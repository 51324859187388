import { createApp } from "vue";
import App from "./App.vue";
import { GettyUICore } from "@thegetty-private/getty-ui";
import { messages } from "@/locales/index.js";
import { appSupport } from "@thegetty-private/rad-app-utils";
import router from "./router";
import Plausible from "plausible-tracker";

let app = createApp(App);

app.use(router);

const { enableAutoPageviews } = Plausible({
  // trackLocalhost: true,
  domain: "data.getty.edu"
});

enableAutoPageviews();

appSupport.setupSentry(
  app,
  "api-documentation",
  process.env.VUE_APP_COMMIT_HASH
);
appSupport.setupGoogleAnalytics(app, undefined, true);

const breakingWarns = [
  "You may have an infinite update loop in a component render function."
];

app.use(GettyUICore, {
  i18nConfig: { locale: "en", fallbackLocale: "en", messages: messages }
});

app.use(GettyUICore);

app.config.warnHandler = (msg, _vm, _trace) => {
  if (breakingWarns.includes(msg)) {
    throw new Error(msg);
  }
};

if (window.Cypress) {
  // Expose Vue errors to Cypress as uncaught exceptions
  app.config.errorHandler = function (err /*, vm, info*/) {
    setTimeout(() => {
      throw err;
    });
  };
}

app.mount("#app");
