<template>
  <div id="app">
    <Ribbon v-if="bannerText != undefined" :text="bannerText" />
    <router-view />
  </div>
</template>

<script>
import { Ribbon } from "@thegetty-private/getty-ui";
import * as configHelper from "@/helpers/configHelper";

export default {
  name: "App",
  components: {
    Ribbon
  },
  props: {},
  computed: {
    /**
     * The Path that the App runs at
     */
    path() {
      let path = "";
      return path;
    },
    /**
     * The status Banner text
     */
    bannerText() {
      return configHelper.getConfigValue("LOCATION_MESSAGE");
    }
  }
};
</script>

<style></style>
